import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import SEO from "../component/SEO/seo"
import Layout from "../component/layout"
import WelcomeMessage from "../component/welcome"
// import { EmailIcon, GithubIcon, LinkedInIcon, TwitterIcon } from "../component/icons";
import {colors, breakpointsMapMax as mediaQuery} from "../styles/theme"


const ContactMe = styled.div`
    font-family: source-sans-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;

    a {
        background: linear-gradient(to bottom, ${colors.gold} 0%, ${colors.gold} 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 4px;
        color: #000;
        text-decoration: none;
        transition: background-size 0.2s;
    
        &:hover {
            background-size: 4px 50px;
        }
    }

    ${mediaQuery[2]} {
        padding: 2rem;
    }
`;

const SocialLink = styled.li`
    margin-bottom: 0.5rem;
`;


export default () => {
    const seo = {
        frontmatter: {
            title: "Contact",
            slug: "contact",
        }
    };
    
    return (
        <Layout>
            <SEO {...seo} />
            <WelcomeMessage>Always happy to hear from you!</WelcomeMessage>
            <ContactMe>
                <p>Get in touch via Email:&nbsp;&nbsp;
                    <a href="mailto:hi@sntnupl.com" alt="Email Address: hi@sntnupl.com">hi@sntnupl.com</a>
                </p>
                <p css={css`
                    margin-top: 2.5rem;
                    margin-bottom: 0rem;
                `}
                >
                    Other ways to reach out:
                </p>
                <ul css={css`
                    margin-top: 0.5rem;
                `}
                >
                    <SocialLink>
                        <a 
                            href="https://linkedin.com/in/sntnupl" 
                            alt="LinkedIn Profile: https://linkedin.com/in/sntnupl"
                        >
                            LinkedIn (in/sntnupl)
                        </a>
                    </SocialLink>
                    <SocialLink>
                        <a 
                            href="https://twitter.com/sntnupl" 
                            alt="Twitter Profile: https://twitter.com/sntnupl"
                        >
                            Twitter (@sntnupl)
                        </a>
                    </SocialLink>
                    <SocialLink>
                        <a 
                            href="https://github.com/sntnupl" 
                            alt="Github Profile: https://github.com/sntnupl"
                        >
                            Github (sntnupl)
                        </a>
                    </SocialLink>
                </ul>
            </ContactMe>
        </Layout>
    );
};




//const ContactEmail = styled.a`
//     display: flex;
//     flex-direction: row;
// `;



// const EmailAddress = styled.span`
//     font-family: source-sans-pro, sans-serif;
//     font-weight: 300;
//     font-style: normal;
//     font-size: 4rem;
//     padding-bottom: 20px;
//     padding-top: 0px;
//     margin-top: 0px;
//     color: ${colors.textDark};

//     ${mediaQuery[1]} {
//         font-size: 2rem;
//         padding-bottom: 0px;
//     }
// `;

// const ContactSocial = styled.div`
//     display: flex;
//     margin-top: 5rem;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: flex-start;

//     ${mediaQuery[1]} {
//         display: none;
//     }
// `;

// const ContactSocialSmall = styled.div`
//     display: none;
//     margin-top: 2.5rem;
//     flex-direction: row;
//     justify-content: flex-start;

//     ${mediaQuery[1]} {
//         display: flex;
//     }
// `;

// export default ({ data }) => {
//     const seo = {
//         frontmatter: {
//             title: "Contact",
//             slug: "contact",
//         }
//     };
    
//     return (
//         <Layout>
//             <SEO {...seo} />
//             <WelcomeMessage>Always happy to hear from you!</WelcomeMessage>
//             <ContactMe>
//                 <ContactEmail href="mailto:hi@sntnupl.com">
//                     <EmailIcon />
//                     <EmailAddress>hi@sntnupl.com</EmailAddress>
//                 </ContactEmail>
//                 <ContactSocial>
//                     <GithubIcon useSmall={false} />
//                     <LinkedInIcon useSmall={false} />
//                     <TwitterIcon useSmall={false} />
//                 </ContactSocial>
//                 <ContactSocialSmall>
//                     <GithubIcon useSmall={true} />
//                     <LinkedInIcon useSmall={true} />
//                     <TwitterIcon useSmall={true} />
//                 </ContactSocialSmall>
//             </ContactMe>
//         </Layout>
//     )
// };